<template>
  <div>

    <div class="word-box" v-for="word in wordList">
      <div class="image-box">
        <ImagePreview :src="word.image" :width="220"/>
      </div>

      <div class="word-info">
        <p class="word-name-box">
          <span class="word-name">{{word.wordName}}</span>
          <span v-if="word.pinYin.length>0">拼音：{{word.pinYin}}</span>
          <span v-if="word.english.length>0">英文：{{word.english}}</span>
        </p>
        <p class="word-desc"><pre>{{word.description}}</pre></p>

        <p class="word-extension"  v-if="word.extension.length>0">引伸词汇：<br/>{{word.extension}}</p>

        <div class="word-from" v-if="word.wordTypeList!=null && word.wordTypeList.length>0">
          <p class="word-from-title">引自：</p>

          <p v-for="type in word.wordTypeList">
            {{type.fullName}}
          </p>

        </div>
      </div>

      <div style="clear: both"></div>
    </div>

    <div style="clear: both"></div>
  </div>
</template>

<script>
  export default {
    name: "WordViewer",
    props: {
      value: [String, Object, Array],
    },
    data() {
      return {
        wordList: []
      }
    },
    watch: {
      value: {
        handler(val) {
          if (val) {
            if (Array.isArray(val)) {
              this.wordList = val;
            } else {
             //this.wordList = new Array();
              this.wordList.push(val);
            }

          } else {

          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>

<style scoped>
  .word-box{
    width: 100%;
    border-bottom: 1px dashed  #bebebe;

    margin-bottom: 2rem;
    /*padding: 0 0.8rem;*/
    padding-bottom: 0.8rem;
  }

  .image-box{
    float: left;
    margin-right: 1.2rem;
  }

  .word-info{
    width: calc(100% - 300px);
    float: left;
  }
  .word-info p{
    text-align: left;
  }

  .word-name-box{

  }
  .word-name{
    font-weight: bold;
    font-size: 1rem;
  }
  .word-name-box span{
    margin-right: 2rem;
  }

  .word-desc{}



  .word-from{
    margin-top: 3rem;
  }

  .word-from-title{
    font-weight: bold;
  }

  pre{
    white-space: break-spaces;
  }

  @media(max-width: 1160px) {
    .image-box{
      width: 100%;
    }

    .word-info{
      width: 100%;
    }

    .word-from{
      margin-top: 0.5rem;
    }

  }

</style>
