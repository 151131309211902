<template>
  <div class="content-container">

    <div class="category-box" :class="{'show' : isShow}"  ref="categoryBox"
         :style="{left: left + 'px',top: top + 'px'}" >
      <subject-category-tree v-model="currentCategoryId" :show-checkbox="true" @checked="checkHandler" @expanded="expandHandler" @collapsed="collapseHandler"/>
    </div>
    <div class="subject-box">
      <div class="image-box">
        <ImagePreview :src="answer.image" alt="" :max-height="260"/>
      </div>

      <div class="subject-buttons">
        <el-button plain @click="showAnalysis" :disabled="!canShowAnalysis">查看解析</el-button>
        <el-button plain @click="next" :loading="loadingNext">下一题</el-button>
      </div>

      <div class="choice-subject-options">
        <div class="subject-option" v-for="(option,index) in options" :key="option.wordName">
          <div class="option-info">


            <div class="option-content">
              <el-radio v-model="selectedOption" name="option" :label="option.wordName" border size="medium" @change="toggleOption(option)">{{ option.wordName }}</el-radio>
            </div>
            <div class="choice-result" v-if="isShowResult">
              <img src="@/assets/img/exam/Right.png" v-if="option.wordName==answer.wordName"/>
              <img src="@/assets/img/exam/Wrong.png" v-if="option.wordName!=answer.wordName"/>
            </div>

          </div>

          <word-viewer v-model="options[index]" v-if="isShowingAnalysis"/>

        </div>

        <div style="clear: both"></div>

      </div>
    </div>

    <float-button v-if="isPhone">
      <img src="@/assets/img/core-img/category.png" style="margin: 15%;width: 70%"  @click="showCategory"/>
    </float-button>


  </div>
</template>

<script>
  const {body} = document;
  const WIDTH = 1060; // refer to Bootstrap's responsive design

  import {imageChoiceWord} from "../../api/exam/practice";
  import WordViewer from '@/components/WordViewer'
  import SubjectCategoryTree from '@/components/Subjects/SubjectCategoryTree'
  import FloatButton from '@/components/FloatButton'
  import {wexinShare} from '@/utils/weixinSDK'

  export default {
    name: "imageChoiceWord",
    components: {WordViewer, SubjectCategoryTree, FloatButton},
    data() {
      return {
        loadingNext: false,
        currentCategoryId: null,
        query: {
          categoryIds: []
        },
        answerImg: null,
        answer: {
          id: null,
          image: ''
        },
        selectedOption:'',
        options: [],
        isShowResult: false,
        canShowAnalysis: false,
        isShowingAnalysis: false,
        shareData: {
          title: '',
          description: '',
          url: '',
          imgUrl: '',
        },

        isPhone:false,
        top:0,
        left:0,
        isSelected: false,
        isShow: false,
      }
    },
    created() {
      this.getSubject();
      this.wxShareInit();
    },
    mounted() {
      this.resizeHandler()
    },
    beforeMount() {
      window.addEventListener('resize', this.resizeHandler)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resizeHandler)
    },
    methods: {
      getSubject() {
        this.loadingNext = true;
        imageChoiceWord(this.query).then((resp) => {
          this.answer = resp.data.answer;
          this.options = resp.data.optionList;
          this.loadingNext = false;
        }).catch((error) => {
          // console.log(error)
          messageFail(this, '获取题目失败')
          this.loadingNext = false;
          window.scroll(0,0);
        });
      },
      next() {
        this.isShowingAnalysis = false;
        this.canShowAnalysis = true;
        this.isShowResult = false;
        this.getSubject();
      },
      showAnalysis() {
        this.isShowingAnalysis = true;
      },
      toggleOption(option) {
        this.isShowResult = true;
        this.canShowAnalysis = true;
      },
      checkHandler(nodes) {
        this.query.categoryIds = nodes.checkedKeys;
        this.getSubject();
        this.showCategory();
        // this.currentCategoryId = data.id;
        // this.categoryIds.categoryIds = this.currentCategoryId;
      },


      isMobile(){
        const rect = body.getBoundingClientRect();
        var isMobile= rect.width - 1 < WIDTH;
        this.isPhone=isMobile;
        return isMobile;
      },
      resizeHandler() {
        if (document.hidden) {
          return;
        }

        const isMobile = this.isMobile()


      },
      setPosition(){
        var clientW=document.documentElement.clientWidth;
        var clientH=document.documentElement.clientHeight;
        var boxW=this.$refs.categoryBox.clientWidth;
        var boxH=this.$refs.categoryBox.clientHeight;

        var left=10;
        var top=clientH-boxH-10;
        this.left=left;
        this.top=top;

      },
      showCategory(){

        // 取反
        this.isSelected = !this.isSelected;

        // 修改列表的显示与隐藏
        if (this.isSelected === true) {
          this.isShow = this.isShow === false;

        } else {
          this.isShow = this.isShow === false;
        }

        if(this.isShow){
          this.setPosition();
        }

      },
      expandHandler(data){
        console.log('expandHandler')
        var that = this;
        var t1 = setTimeout(function () {
          that.setPosition();
          clearTimeout(t1);
        }, 300)

      },
      wxShareInit() {
        var shareUrl = window.location.href;
        this.shareData.title = '看图选词';
        //this.shareData.imgUrl = this.exam.logoUrl;
        this.shareData.url = shareUrl;
        this.shareData.description = '看图选择手语词汇，您能选对多少个？';

        wexinShare(this.shareData);

      },
      collapseHandler(data){
        console.log('collapseHandler')
        var that = this;
        var t1 = setTimeout(function () {
          that.setPosition();
          clearTimeout(t1);
        }, 300)
      }


    }
  }
</script>

<style scoped>
  @import "../../assets/css/imageChoiceWord.scss";

  .image-box {
    margin: 0 auto;
    text-align: center;
  }




  @media(max-width: 1160px) {

    .choice-subject-options{
      margin-left: 1rem;
    }

    .category-box{
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid #efefef;
      background: transparent;
      visibility: hidden;
      opacity: 0;
      z-index: 999;
      overflow-y: scroll;
    }

    .category-box.show {
      width: 80%;
      max-width: 360px;
      visibility: visible;
      opacity: 1;
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      background: white;
      /*height: 80vh;*/
      max-height: 80vh;
    }


    .subject-box{
      width: 100% !important;
    }


    .subject-buttons,.subject-options{
      margin-top: 1rem !important;
    }
  }

</style>
